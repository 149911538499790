import React, { useMemo, useState } from 'react';
import { StripeStudents } from '@/components/StripeCheckout/StripePlans';
import cx from 'classnames';

const TeacherPlanButtons = ({ selectedPlan, setSelectedPlan, selectedStudents }) => {
    const [originalSelectedPlan, setOriginalSelectedPlan] = useState(selectedPlan);

    const plans = useMemo(() => {
        return StripeStudents[selectedStudents]?.plans.map((plan, index) => {
            const style = {};
            const styleInner = {};
            const isSelected = index === selectedPlan;
            const classes = cx(
                'select-none cursor-pointer text-left',
                'basis-full w-full max-w-[275px] sm:max-w-[425px] p-[3.7px]  shadow-checkout-teacher flex-col justify-center items-start gap-2 inline-flex',
                // 'border-[2px] border-[transparent]',
                'rounded-[14px]',
                // 'bg-clip-padding ',
                'p-2'
            );

            const innerClasses = cx('w-full h-[65px] sm:h-[90px] sm:h-full px-0 sm:px-1 sm:py-3.5 bg-white rounded-[10px]');
            if (originalSelectedPlan !== index) return null;
            return (
                <div
                    tabIndex={index + 1}
                    key={plan.id}
                    // style={style}
                    onClick={(event) => setSelectedPlan && setSelectedPlan(event, index, plan)}
                    className={classes}
                    style={{ boxShadow: '0px 13.542px 30.041px 0px rgba(82, 67, 194, 0.30)' }}
                >
                    <div className={innerClasses}>
                        <div className='w-full sm:px-3 py-2 sm:py-1.5 flex items-start md:items-center flex-col gap-1 sm:gap-1 '>
                            <div className='DaysFreeThen14999Year text-center'>
                                <span className='hidden md:block text-[#1C0057] text-base sm:text-[18px] xl:text-[24px] font-normal font-Poppins leading-tight'>
                                    {`${plan.amount} for ${plan.name_planButton}`}
                                </span>
                            </div>
                            <div className='block md:hidden text-[#231D4F] font-normal text-base leading-tight font-Poppins text-center w-full'>
                                {`${plan.amount} for ${plan.name_planButton}`}
                            </div>
                            <div className='text-[#848199] text-sm sm:text-base leading-tight font-Poppins mt-1 text-center w-full'>
                                Up to {StripeStudents[selectedStudents].id}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }, [selectedPlan, setSelectedPlan, selectedStudents]);

    return (
        <div className='mt-3 sm:mt-8'>
            <div className='PlanList relative flex justify-center sm:justify-start w-full gap-4 mx-auto max-w-[450px]'>{plans}</div>
        </div>
    );
};

export default TeacherPlanButtons;
