import React from 'react';

const TimesTablePlanButton = () => {
    return (
        <div className='bg-[#4E389D] text-white font-Poppins rounded-[22.973px] text-center py-3 sm:py-5' style={{ boxShadow: '0px 13.542px 30.041px 0px rgba(82, 67, 194, 0.30)' }}>
            <div className='text-2xl sm:text-[34px] leading-10  '>
                Only <span className='text-[33px] sm:text-[48px]'>$9.99</span>
            </div>
            <div className='text-base sm:text-2xl font-medium mt-1'>for 99 days of access</div>
            <div className='max-w-[290px] sm:max-w-[369px] mx-auto leading-6 sm:leading-7 text-base sm:text-xl font-normal mt-4 sm:mt-7'>Science-backed music videos and quizzes to learn each times table! </div>
        </div>
    );
};

export default TimesTablePlanButton;
