import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentWalletOption, TermOption } from '@stripe/stripe-js';
import { ExpressCheckoutElement, LinkAuthenticationElement, PaymentElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FieldOption } from '@stripe/stripe-js/types/stripe-js/elements/payment';
import { MUZOLOGY_API, SANDBOX_MODE } from '@/lib/defaults';
import SubmitButton from '@/components/Checkout/components/SubmitButton';
import { StripePlans, StripeStudents } from '@/components/StripeCheckout/StripePlans';
import {
    BillingDetails, ExpressPaymentType, ShippingAddress, ShippingRate, StripeExpressCheckoutElementOptions
} from '@stripe/stripe-js/types/stripe-js/elements/express-checkout';
import StripeField from '@/components/StripeCheckout/StripeField';
import cx from 'classnames';
import { useLocalStorage } from 'react-use';
import { useRouter } from 'next/router';
import { CheckoutPurchaseError, CheckoutPurchaseSubmitting } from '../../analytics/events';
import { BlockKlaviyo, useAnalytics } from '@/analytics/AnalyticsProvider';
import { createUrlWithParams } from '@/lib/urls';
import { useRollbar } from '@rollbar/react';
import { pushToDataLayer } from '@/lib/ga';
import { MUZOLOGY_APP } from '@/lib/defaults';
import { ApplePayRegularBilling } from '@stripe/stripe-js/types/stripe-js/elements/apple-pay';

const expressOptions: StripeExpressCheckoutElementOptions = {
    buttonHeight: 40, buttonTheme: {
        applePay: 'black', googlePay: 'black'
    }, buttonType: {
        applePay: 'plain', googlePay: 'plain'
    }, layout: {
        maxColumns: 2, maxRows: 2 // overflow: 'never',
    }, paymentMethodOrder: ['google_pay', 'apple_pay', 'paypal', 'link'], wallets: {
        applePay: 'always', googlePay: 'always'
    } // paymentRequest: null,
};

const splitName = (name) => {
    const names = name.split(' ');
    if (names.length === 1) {
        return { firstName: names[0], lastName: '' };
    } else {
        return { firstName: names[0], lastName: names[1] };
    }
};

const findParentStripePlan = (planId) => {
    return StripePlans.find((p) => p.priceId === planId);
};

const findTeacherStripePlan = (planId) => {
    return StripeStudents.find((studentPlan) => {
        return studentPlan.plans.find((p) => p.priceId === planId);
    });
};

const CheckoutForm = ({
    id,
    sessionId,
    intentId,
    className = '',
    planId,
    email,
    setErrors,
    errors,
    name,
    setName,
    setEmail,
    teacher = false,
    accountId = null,
    onComplete,
    timesTables = null,
    discount = null
}) => {
    const rollbar = useRollbar();
    const analytics = useAnalytics();
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [isStripeReady, setStripeIsReady] = useState(false);
    const [tempEmail, setTempEmail] = useState(email);
    const [paymentSetup, setPaymentSetup] = useState(null);
    // const [checkoutState, setCheckoutState, clearCheckoutState] = useLocalStorage('checkout-state');
    const router = useRouter();
    // useEffect(() => {
    //     pushToDataLayer('test_event', { pagePath: router.asPath, page: router.pathname, query: router.query });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    console.log('<CheckoutForm> intent id:', intentId);
    console.log('<CheckoutForm> session id:', sessionId);
    console.log('<CheckoutForm> account id:', accountId);
    console.log('<CheckoutForm> Discount applied:', discount);

    // get the stripe price plan
    let plan = null;
    if (teacher) {
        plan = findTeacherStripePlan(planId);
    } else {
        plan = findParentStripePlan(planId);
    }
    // const plan = StripePlans.find((p) => p.priceId === planId);

    // pushToDataLayer('test_event', { pagePath: router.asPath, page: router.pathname, query: router.query });
    // submit the checkout to stripe
    const submitPurchase = useCallback((express = false, paymentType = 'card') => {
        const validateForm = () => {
            // validate the form
            const errors = {
                name: null, planId: null
            };

            if (!name?.length) {
                errors.name = 'Please enter your name';
            }
            if (planId) {
                setErrors({ planId: null });
            } else {
                setErrors({ planId: 'Please select a plan' });
                errors.planId = 'Please select a plan';
                return;
            }

            setErrors(errors);
            // return false if there are errors
            return !errors.name && !errors.planId;
        };

        const submit = async () => {
            if (!express) {
                // validate the form
                if (!validateForm()) return;
            }

            // if (errors.email || errors.planId) {
            //     return;
            // }

            // make a copy of the checkout state and clear it
            // const tempCheckoutState = { ...(checkoutState as object) };
            // clearCheckoutState();

            // add the email and planId
            // get the card element
            // const cardElement = elements.getElement(CardElement);
            // console.log('card', cardElement);
            // if (!cardElement) {
            //     console.log('card element not found');
            //     return;
            // }
            // http://localhost:3000/checkout?
            //      completion=true&
            //      payment_intent=pi_3OV18IJCkvkbxYUr0UnONVOp&
            //      payment_intent_client_secret=pi_3OV18IJCkvkbxYUr0UnONVOp_secret_k0VcJ4haUdpOqXMSGyhkdNf1e&
            //      redirect_status=succeeded
            setIsLoading(true);

            // identify the user since we now have a name and email
            const { firstName, lastName } = splitName(name);
            /* eslint-disable camelcase */
            await analytics.identify({
                userType: teacher ? 'teacher' : 'parent',
                email,
                name,
                first_name: firstName,
                last_name: lastName
            });

            // track the event
            await analytics.track(CheckoutPurchaseSubmitting, {
                ...router.query,
                userType: teacher ? 'teacher' : 'parent',
                source: 'stripe',
                checkoutType: express ? 'Stripe Express Checkout Button' : 'Stripe Checkout Form',
                paymentType,
                planName: plan?.name,
                recurring: plan?.recurring,
                plan,
                planId,
                email,
                name,
                firstName,
                lastName
            }, BlockKlaviyo);

            // construct the return url
            let return_url = '';
            let redirect = '';
            const args = {
                ...router.query,
                name,
                anonymous_id: analytics.getAnonymousId(),
                plan_id: planId,
                payment_type: paymentType,
                payment_intent: intentId,
                is_teacher: false,
                account_id: null,
                failure_url: null,
                success_url: null,
                discount_id: discount?.id ?? null,
                discount_code: discount?.code ?? null
            };

            // check if this is a teacher or parent purchase, and construct the return url
            if (teacher) {
                // on success bring take them to the server, and then bring them back to this website.
                //    on purchase -> server/api/checkout/complete -> website/educator/:accountId/success

                // construct the success url
                const currentDomain = window.location.origin;
                const successURL = `${currentDomain}/educator/${accountId}/success`;
                args.success_url = encodeURIComponent(successURL);
                args.account_id = accountId;
                args.is_teacher = true;
                redirect = 'always';
                return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
            } else {
                // this is a normal purchase
                //     on purchase -> server/api/checkout/complete -> app

                // @ts-ignore
                args.intent_id = intentId;
                // @ts-ignore
                args.session_id = sessionId;

                if (plan.recurring) {
                    // args.setup_intent = intentId;
                    // args.setup_intent= setupIntent.id
                    // args.redirect_status= setupIntent.status
                } else {
                    // @ts-ignore
                    args.payment_intent = intentId;
                }

                // args.failure_url = `${currentDomain}/educator/${accountId}/success`;
                // return_url = `${MUZOLOGY_API}/api/checkout/complete/?plan_id=${planId}&name=${name}`;
                // redirect = 'always';
                // if (plan.recurring)
                //     redirect = 'if_required';
                // else
                //     redirect = 'always';
                return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
                redirect = 'if_required';
            }

            console.log('[Stripe] return_url', return_url);

            // check if this is recurring
            let response = null;
            const confirmArgs = {
                elements, // @ts-ignore
                redirect,
                confirmParams: {
                    return_url,
                    payment_method_data: {
                        billing_details: {
                            // name: 'NAME',
                            email
                        }
                    }
                }
            };

            // perform analytics events here

            // confirm the payment with stripe
            if (plan.recurring) {
                // confirm the setup intent for a recurring payment (collect payment details)
                // @ts-ignore
                response = await stripe.confirmSetup(confirmArgs);
                console.log('[stripe] confirmSetup response', response);
                const { setupIntent } = response;
                console.log('[stripe] checkout status', setupIntent?.status);
            } else {
                // a one time payment, confirm the payment intent
                console.log('[stripe] calling confirmPayment');
                // @ts-ignore
                response = await stripe.confirmPayment(confirmArgs);
                console.log('[stripe] confirm payment response', response);
            }

            // check for error
            const { error } = response ?? {};
            if (error) {
                console.warn('stripe error:', error);

                // set the error message
                setMessage(error.message);
                setIsLoading(false);

                // inform rollbar
                rollbar?.error('Stripe Error', error);

                // track the event
                analytics.track(CheckoutPurchaseError, {
                    response,
                    error, ...router.query,
                    userType: teacher ? 'teacher' : 'parent',
                    name,
                    email,
                    source: 'stripe',
                    planId,
                    planName: plan?.name,
                    recurring: plan.recurring,
                    plan
                }, BlockKlaviyo);

                // if (error.type === "card_error" || error.type === "validation_error") {
                //     setMessage(error.message);
                // } else {
                //     setMessage("An unexpected error occurred.");
                // }
                return;
            }

            console.log('<CheckoutForm> stripe checkout complete - calling onComplete()');

            return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);

            // track the event
            const onSuccess = () => {
                console.log('<CheckoutForm> onSuccess - redirecting to server to complete purchase');
                // redirect to the server to complete the purchase
                const anonymousId = analytics.getAnonymousId();

                // const args = {
                //     name,
                //     anonymous_id: anonymousId,
                //     plan_id: planId,
                //     is_teacher: teacher,
                //     account_id: accountId,
                //     payment_type: paymentType,
                //     payment_intent: intentId,
                // };

                // check if the response is a setup intent
                if (response) {
                    console.log('<CheckoutForm> onSuccess - setup intent response:', response);
                    const { setupIntent } = response;
                    if (setupIntent) {
                        // @ts-ignore
                        args.setup_intent = setupIntent.id;
                        // @ts-ignore
                        args.redirect_status = setupIntent.status;
                    }
                }
                console.log('<CheckoutForm> onSuccess - clearing checkout state');

                if (teacher) {
                    // go to success screen
                    const currentDomain = window.location.origin;
                    const successURL = `${currentDomain}/educator/${accountId}/success`;
                    console.log(`redirecting to ${successURL} on completion`);
                    window.location.href = successURL;
                } else {
                    // redirect to the site to begin onboarding - we need a full url to the server to complete the purchase
                    const completeURL = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
                    console.log(`redirecting to ${completeURL} on completion`);
                    window.location.href = completeURL;
                }
            };

            // call the completion handler, then onSuccess
            onComplete?.('stripe', response, onSuccess);

            // checkout complete - redirect to the return url
            console.log('[stripe] checkout complete, redirecting to', return_url);
            window.location.href = return_url;

            // analytics.track(CheckoutPurchaseCompleteFrontend, {
            //     ...router.query,
            //     name,
            //     email,
            //     source: 'stripe',
            //     planId, planName:
            //     plan?.name, plan,
            //     purchase: response,
            //     testMode: SANDBOX_MODE
            // });

            // await completeCheckout(name, planId, setupIntent);
            // get the card element
            // const cardElement = elements.getElement(CardElement);
            // console.log(cardElement);
            // const { error, paymentMethod } = await stripe.createPaymentMethod({
            //     type: 'card', card: cardElement,
            // });
            //
            // if (error) {
            //     console.log('[error]', error);
            // } else {
            //     console.log('[PaymentMethod]', paymentMethod);
            //     // Handle payment success
            // }
        };
        submit()
            .then((r) => console.log(r))
            .catch((e) => console.log(e));
    }, [name, planId, setErrors, analytics, teacher, email, router.query, plan, intentId, elements, onComplete, accountId, sessionId, stripe, rollbar]);

    const handleSubmitCheckout = (event) => {
        console.log('Checkout Form Submitted');
        event.preventDefault();
        submitPurchase();
    };

    const handleExpressCheckout = (events) => {
        console.log('Express Checkout Submitted');
        const {
            // paymentFailed,
            // billingDetails,
            // shippingAddress,
            // shippingRate,
            expressPaymentType
        } = events;

        submitPurchase(true, expressPaymentType);
    };

    // const old_handleExpressCheckout = useCallback((event) => {
    //     event.preventDefault();
    //     console.log('express checkout event', event);
    //     const submit = async () => {
    //         setIsLoading(true);
    //         // console.log(`sending to ${MUZOLOGY_API}/api/checkout/complete/ on completion`);
    //
    //         // create a temp copy of the checkout state in case of an error, we can replace it
    //         // on success the user will be redirected to the site so we have to perform cleanup first
    //         const tempCheckoutState = {
    //             ...(
    //                 checkoutState as object
    //             )
    //         };
    //         clearCheckoutState();
    //
    //         // const { firstName, lastName } = splitName(name);
    //
    //         // track the event
    //         analytics.track(CheckoutPurchaseSubmitting, {
    //             ...router.query,
    //             userType: teacher ? 'teacher' : 'parent',
    //             source: 'stripe',
    //             checkoutType: 'Stripe Express Checkout Button',
    //             planName: plan?.name,
    //             recurring: plan?.recurring,
    //             plan,
    //             planId
    //         });
    //
    //         const return_url = `${MUZOLOGY_API}/api/checkout/complete/`;
    //
    //         /* eslint-disable camelcase */
    //         let response = null;
    //         if (plan.recurring) {
    //             response = await stripe.confirmSetup({
    //                 elements, redirect: 'if_required', confirmParams: {
    //                     return_url: `${MUZOLOGY_API}/api/checkout/complete/`, payment_method_data: {
    //                         billing_details: {
    //                             // name: 'NAME',
    //                             email
    //                         }
    //                     }
    //                 }
    //             });
    //             console.log('stripe confirm checkout response', response);
    //         } else {
    //             // a one time payment, confirm the payment intent
    //             response = await stripe.confirmPayment({
    //                 elements, confirmParams: {
    //                     return_url, payment_method_data: {
    //                         billing_details: {
    //                             email
    //                         }
    //                     }
    //                 }
    //             });
    //             console.log('stripe confirm payment response', response);
    //         }
    //
    //         /* eslint-enable camelcase */
    //         const { error, setupIntent } = response;
    //         if (error) {
    //             console.error('Stripe Error', error);
    //             rollbar?.error('Stripe Error', error);
    //             analytics.track(CheckoutPurchaseError, {
    //                 response, error, ...router.query, userType: teacher ? 'teacher' : 'parent', name, email, source: 'stripe', planId, planName: plan?.name, plan
    //             });
    //
    //             if (teacher) {
    //                 // on success bring take them to the server, and then bring them back to this website.
    //                 //    on purchase -> server/api/checkout/complete -> website/educator/:accountId/success
    //                 // const successURL = `${currentDomain}/educator/${accountId}/success`;
    //
    //                 // args.is_teacher = true;
    //                 // args.account_id = accountId;
    //                 // args.success_url = encodeURIComponent(successURL);
    //                 //
    //                 // redirect = 'always';
    //                 // return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
    //             } else {
    //                 setCheckoutState(tempCheckoutState);
    //                 setIsLoading(false);
    //             }
    //
    //             return;
    //         }
    //
    //         // handle checkout completion
    //         onComplete?.('stripe', response, () => {
    //             // redirect to the server to complete the purchase
    //             const anonymousId = analytics.getAnonymousId();
    //             /* eslint-disable camelcase */
    //             const args = {
    //                 name, anonymous_id: anonymousId, plan_id: planId, setup_intent: setupIntent.id, redirect_status: setupIntent.status, teacher, accountId
    //             };
    //             /* eslint-enable camelcase */
    //
    //             // we need a full url to the server to complete the purchase
    //             const completeURL = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
    //             console.log(`redirecting to ${completeURL} on completion`);
    //             window.location.href = completeURL;
    //         });
    //     };
    //     submit()
    //         .then((r) => console.log(r))
    //         .catch((e) => console.log(e));
    // }, [accountId, analytics, checkoutState, clearCheckoutState, elements, email, name, planId, router.query, setCheckoutState, stripe, teacher]);

    const notice = useMemo(() => {
        function getChargeDate() {
            const today = new Date();
            today.setDate(today.getDate() + 7);

            return today.toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        }
        const chargeDate = getChargeDate();

        if (planId) {
            setErrors({ planId: null });
            if (teacher) {
                const plan = StripeStudents.map((studentPlan) => studentPlan.plans.find((p) => p.priceId === planId)).filter((plan) => plan !== undefined)[0];
                if (plan) {
                    const { amount, duration, notice_text2, name } = plan;
                    // return `Enjoy ${name.toLowerCase()} of Muzology access with a one-time payment of ${amount}. This is not a recurring subscription.`;

                    return `You will be charged ${amount} ${duration}. ${notice_text2}`;
                }
            } else {
                // get the plan
                const plan = StripePlans.find((p) => p.priceId === planId);
                if (plan) {
                    const { amount, duration, notice_text2 } = plan;
                    if (plan.id === 'times_tables') {
                        return (
                            <div>
                                This is a one-time charge of $9.99 for 99 days of Musical Times Tables access. By signing up you agree to Muzology’s{' '}
                                <span className='underline cursor-pointer'>
                                    <a className='text-black' href='/tos'>
                                        Terms of Use
                                    </a>
                                </span>{' '}
                                and{' '}
                                <span className='underline cursor-pointer'>
                                    {' '}
                                    <a className='text-black' href='/privacy' target='_blank'>
                                        Privacy Policy
                                    </a>
                                    .
                                </span>
                                <div className='text-black text-[16px] font-normal font-Poppins leading-snug text-center mt-3 '>
                                    Already have an account?{' '}
                                    <a href={MUZOLOGY_APP} className='underline'>
                                        Log in here.
                                    </a>
                                </div>
                            </div>
                        );
                    }
                    // const date = 'December 25, 2023';
                    // get the date 7 days in the future
                    const date = new Date();
                    date.setDate(date.getDate() + 7);
                    // format date as December 25, 2023
                    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                    // return `After your trial ends, you will be charged ${amount} per ${duration} starting ${formattedDate}. Easily cancel within 7 days and you will not be charged.`;
                    return `Start your 7-day free trial today! Your first charge of $105 will be on ${chargeDate}. After that, your subscription renews annually. Cancel anytime before the trial ends to avoid charges.`;
                    // if (duration === 'for a 3 month subscription') {
                    //     return `This is a recurring subscription. You will be charged ${amount} every 3 months. ${notice_text2}`;
                    // } else {
                    //     // return `Start your 7-day free trial today! On ${subscription_start}, your subscription will automatically renew at $105 per year. You can cancel anytime before the trial ends to avoid charges`;
                    //     return ` This is a recurring subscription. You will be charged ${amount} per ${duration}. ${notice_text2}`;
                    // }
                }
            }
        }
        return '';
    }, [planId, setErrors, teacher]);
    const showNotice = Boolean(notice);

    const options = {
        // disableWallets: ['googlePay', 'applePay'],
        wallets: {
            googlePay: 'never' as PaymentWalletOption,
            applePay: 'never' as PaymentWalletOption
        },
        appearance: {
            layout: 'tabs'
        },
        terms: {
            card: 'never' as TermOption
        },
        paymentMethodTypes: ['card'],
        business: {
            name: 'muzology'
        },
        defaultValues: {
            billingDetails: {
                email
            }
        },
        fields: {
            billingDetails: {
                email: 'auto' as FieldOption,
                name: 'auto' as FieldOption,
                address: 'auto' as FieldOption
            }
        }

        // layout: {
        // paymentMethods: [
        //     'card',
        //     'paypal',
        //     // 'google_pay'
        // ],
        //     // type: 'tabs',
        //     // defaultCollapsed: false,
        //     // radios: true,
        //     // spacedAccordionItems: true,
        // },
        // terms: {
        //     // applePay: 'always',
        //     // auBecsDebit: 'auto',
        //     // bancontact: 'auto',
        //     // card: 'always',
        //     cashapp: 'never',
        //     // googlePay: 'always',
        //     // ideal: 'auto',
        //     // paypal: 'always',
        //     // sepaDebit: 'auto',
        //     // sofort: 'auto',
        //     // usBankAccount: 'auto',
        // }
    };

    // const [localName, setLocalName] = useState(name);
    const handleBlur = useCallback((event) => {
        setName(event.target.value);
    }, [setName]);


    const paymentRequest = useMemo(async () => {
        if (!stripe) return null;
        console.log('setup payment request', paymentSetup);
        if (paymentSetup) {
            return paymentSetup;
        }

        const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Total',
                amount: 999 // This should be in the smallest currency unit, like cents for USD
            },
            requestPayerName: true,
            requestPayerEmail: true,
            wallets: ['applePay', 'googlePay']
            // applePay: {
            //     recurringPaymentRequest: {
            //         paymentDescription: 'description',
            //         managementURL: '',
            //         regularBilling: {
            //             label: 'label',
            //             amount: 999
            //         }
            //     }
            // },
            // requiredBillingContactFields: ['postalAddress', 'email', 'name', 'phone'],
        });

        paymentRequest.canMakePayment().then((result) => {
            if (result) {
                const prButton = elements.create('paymentRequestButton', { paymentRequest });
                prButton.mount('#payment-request-button');
            } else {
                // Handle the case when the payment request button is not available
                document.getElementById('payment-request-button').style.display = 'none';
            }
        });
        paymentRequest.on('token', (ev) => {
            console.log('<CheckoutForm> on payment token', ev);

            setIsLoading(true);

            // You can access the email from the event object
            const { payerEmail: email, payerName: name, walletName, token } = ev;
            const { id: tokenId, client_ip: clientIP, type: tokenType } = token;
            // console.log('token', { tokenId, tokenType, clientIP });

            // identify the user since we now have a name and email
            const { firstName, lastName } = splitName(email);
            console.log('payment request token', { email, name, firstName, lastName, walletName, token });

            /* eslint-disable camelcase */
            analytics.identify({
                userType: teacher ? 'teacher' : 'parent', email, name, first_name: firstName, last_name: lastName
            });

            // track the event
            analytics.track(CheckoutPurchaseSubmitting, {
                ...router.query,
                userType: teacher ? 'teacher' : 'parent',
                source: 'stripe',
                checkoutType: 'Stripe Checkout Button',
                paymentType: walletName,
                planName: plan?.name,
                recurring: plan?.recurring,
                plan,
                planId,
                email,
                name,
                firstName,
                lastName
            }, BlockKlaviyo);

            // construct the return url
            let redirect = '';
            const anon = analytics.getAnonymousId();
            const args = {
                ...router.query,
                name,
                email,
                anonymous_id: analytics.getAnonymousId(),
                plan_id: planId,
                payment_type: walletName,
                is_teacher: false,
                account_id: accountId,
                token_id: tokenId,
                token_type: tokenType,
                failure_url: null,
                success_url: null
            };
            console.log('<CheckoutForm> Checkout args:', args);
            // check if this is a teacher or parent purchase
            const currentDomain = window.location.origin;

            // return_url is the url that the server will redirect to after the purchase is complete (an endpoint on our server)
            // success_url is where the user will be redirected by the server after the purchase is complete (typically the application frontend)

            if (teacher) {
                // on success bring take them to the server, and then bring them back to this website.
                //    on purchase -> server/api/checkout/complete -> website/educator/:accountId/success
                const successURL = `${currentDomain}/educator/${accountId}/success`;

                args.is_teacher = true;
                args.account_id = accountId;
                args.success_url = encodeURIComponent(successURL);

                redirect = 'always';
                // return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
            } else {
                // @ts-ignore
                args.intent_id = intentId;
                if (plan.recurring) {
                    // args.setup_intent = intentId;
                    // args.setup_intent= setupIntent.id
                    // args.redirect_status= setupIntent.status
                } else {
                    // @ts-ignore
                    args.payment_intent = intentId;
                }
                // @ts-ignore
                args.session_id = sessionId;
                args.account_id = accountId;
                // args.failure_url = `${currentDomain}/educator/${accountId}/success`;
                // return_url = `${MUZOLOGY_API}/api/checkout/complete/?plan_id=${planId}&name=${name}`;
                // return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
                // redirect = 'always';
                // if (plan.recurring)
                //     redirect = 'if_required';
                // else
                //     redirect = 'always';
            }

            // construct the return url
            const return_url = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
            console.debug('<CheckoutForm> Checkout args:', args);
            console.debug('<CheckoutForm> Checkout redirect:', return_url);

            // check if this is recurring
            const response = null;
            if (plan.recurring) {
                // confirm the setup intent for a recurring payment (collect payment details)
                // @ts-ignore
                // const confirmArgs = {
                //     elements, // @ts-ignore
                //     redirect, confirmParams: {
                //         return_url, payment_method_data: {
                //             billing_details: {
                //                 // name: 'NAME',
                //                 email
                //             }
                //         }
                //     }
                // };
                // // response = stripe.confirmSetup(confirmArgs);
                // console.log('stripe confirm checkout response', response);
                // const { setupIntent } = response;
                console.log('stripe confirm recurring payment response', response);
            } else {
                // a one time payment, confirm the payment intent
                // @ts-ignore
                // response = await stripe.confirmPayment(confirmArgs);
                console.log('stripe confirm payment response', response);
            }
            // // check for error
            // const { error } = response;
            // if (error) {
            //     console.warn('stripe error:', error);
            //
            //     // set the error message
            //     setMessage(error.message);
            //     setIsLoading(false);
            //
            //     // inform rollbar
            //     rollbar?.error('Stripe Error', error);
            //
            //     // track the event
            //     analytics.track(CheckoutPurchaseError, {
            //         response,
            //         error,
            //         ...router.query,
            //         userType: teacher ? 'teacher' : 'parent',
            //         name,
            //         email,
            //         source: 'stripe',
            //         planId,
            //         planName: plan?.name,
            //         recurring: plan.recurring,
            //         plan
            //     });
            //
            //     // if (error.type === "card_error" || error.type === "validation_error") {
            //     //     setMessage(error.message);
            //     // } else {
            //     //     setMessage("An unexpected error occurred.");
            //     // }
            //     return;
            // }
            //
            // // track the event
            console.log('<CheckoutForm> stripe checkout complete - calling onComplete()');
            onComplete?.('stripe', response, () => {
                // redirect to the server to complete the purchase
                console.log('<CheckoutForm> onComplete - redirecting to server to complete purchase');
                // const anonymousId = analytics.getAnonymousId();
                // const args = {
                //     name,
                //     anonymous_id: anonymousId,
                //     plan_id: planId, is_teacher: teacher, account_id: accountId
                // };
                if (response) {
                    const { setupIntent } = response;
                    if (setupIntent) {
                        // @ts-ignore
                        args.setup_intent = setupIntent.id;
                        // @ts-ignore
                        args.redirect_status = setupIntent.status;
                    }
                }
                if (teacher) {
                    // go to success screen
                    console.log('go to teacher active screen 2');
                    // router.push(`/educator/${accountId}/active`);
                    console.log(`redirecting to ${return_url} on completion`);
                    window.location.href = return_url;
                } else {
                    // redirect to the site to begin onboarding - we need a full url to the server to complete the purchase
                    // console.log("<CheckoutForm> Redirecting with args:", args);
                    // const completeURL = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/complete/`, args);
                    console.log(`redirecting to ${return_url} on completion`);
                    window.location.href = return_url;
                }
            });
            // Send token and email to your server to process the payment
        });

        setPaymentSetup(paymentRequest);
        return paymentRequest;
        // }, [elements, paymentSetup, stripe]);
    }, [accountId, analytics, elements, intentId, onComplete, paymentSetup, plan, planId, router.query, sessionId, stripe, teacher]);

    // expressOptions.paymentRequest = paymentRequest;
    return (
        <form id={id} onSubmit={handleSubmitCheckout}>
            <div className={`${className} flex flex-col gap-4 text-black`}>
                <div id='payment-request-button' />

                {/*
                <ExpressCheckoutElement
                    options={expressOptions}
                    onConfirm={handleExpressCheckout}
                    // paymentRequest={paymentRequest}
                />
                */}

                <LinkAuthenticationElement
                    id='link-authentication-element'
                    onBlur={() => setEmail(tempEmail)}
                    onChange={(event) => {
                        setTempEmail(event.value.email);
                    }}
                    options={{ defaultValues: { email } }}
                />

                <StripeField
                    name='name'
                    label='Name'
                    value={name}
                    onBlur={handleBlur}
                    onChange={setName}
                    error={errors?.name}
                    className={cx({ hidden: !isStripeReady })}
                />

                {/* Show any error or success messages */}
                {/*{message && <div id="payment-message">{message}</div>}*/}
                <PaymentElement
                    options={options}
                    onReady={(element) => setStripeIsReady(true)}
                />

                {/*
                <AddressElement options={{
                    mode: 'billing',
                    fields: {
                        phone: 'never'
                    }
                }} />
                */}

                {/*<CardElement options={CARD_ELEMENT_OPTIONS}/>*/}
                <SubmitButton
                    id='submit-checkout-purchase'
                    className={cx('not-mt-[-56px]', { hidden: !isStripeReady })}
                    isLoading={isLoading}
                    text={timesTables ? ' Start Learning Now!' : 'Start Free Trial'}
                    onClick={handleSubmitCheckout}
                />
                {/*
                <button id='submit' type='submit' disabled={isLoading || !stripe || !elements}
                    className='w-full max-w-[370px] m-auto h-[62px] px-8 py-5 bg-indigo-500 rounded-2xl border-solid border-2 sm:border-white justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-105 transform transition-all duration-300 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'>
                    <div className="text-[#FCFCFC]  text-center text-neutral-50 text-[20px] font-semibold font-Poppins leading-loose tracking-wide">
                        {isLoading ? <ScaleLoader color='white' /> : "Start Now!"}
                    </div>
                </button>
                */}

                {message && <div className={cx('')}>{notice && <div className='text-center text-base text-[red]'>{message}</div>}</div>}

                <div className={cx('', { hidden: !isStripeReady })}>
                    {showNotice && <div className={cx('text-center text-sm text-[#131313]', { 'max-w-[410px] mx-auto': teacher })}>{notice}</div>}
                </div>
                <div className={cx('', { hidden: !isStripeReady })}>
                    {teacher && (
                        <div className='text-center text-sm text-[#131313] hidden sm:block'>
                            <div>
                                {' '}
                                <span className='font-semibold'>Don’t Have a Muzology Account?</span>{' '}
                                <a href='/'>
                                    <span className='underline text-[#131313]'>Click here</span>
                                </a>{' '}
                                to sign up.{' '}
                            </div>
                            <div className='mt-3'>
                                {' '}
                                <span className='font-semibold'>Need Help?</span> Contact us at{' '}
                                <a href='mailto:info@muzology.com' className='text-[#131313] no-underline'>
                                    <span> support@muzology.com</span>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

export default CheckoutForm;

// const CARD_ELEMENT_OPTIONS = {
//     style: {
//         base: {
//             // Add your styles here
//             fontSize: '16px',
//             color: '#424770',
//             '::placeholder': {
//                 color: '#aab7c4',
//             },
//             ':focus': {
//                 color: '#424770',
//             },
//         },
//         invalid: {
//             color: '#9e2146',
//         },
//     },
// };
// // PaymentElementProps;
