import React, { useCallback } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
// import { SubscriptionDetail } from '@paypal/paypal-js/types/apis/subscriptions/subscriptions';
import { MUZOLOGY_API, SANDBOX_MODE } from '@/lib/defaults';
import {
    NewInitialPaidTeacher,
    NewPaidTeacher,
    NewParentInitialPaid, NewParentPaid,
} from '@/analytics/events';
import { BlockKlaviyo, useAnalytics } from '@/analytics/AnalyticsProvider';
import { useRouter } from 'next/router';
import { StripePlans } from '@/components/StripeCheckout/StripePlans';
import { createUrlWithParams } from '@/lib/urls';

const PayPalCheckoutButtons = ({
    planId,
    sessionId,
    token,
    accountId,
    name,
    email,
    recurring,
    setLoading = null,
    fundingSource = null,
    color = null,
    onComplete = null,
    discount = null,
    teacher = false
}) => {
    const analytics = useAnalytics();
    const router = useRouter();

    const payPalStyle = {
        color: color ?? 'gold',
        label: 'paypal',
        layout: 'vertical',
        shape: 'rect',
        tagline: false
        // disableMaxWidth: true,
        // height: 55,
    };

    const applyDiscount = (amount) => {
        if (!discount || !discount.value) return amount;

        const discountValue = parseFloat(discount.value);

        if (discount.type === 'percent') {
            return (amount - (amount * (discountValue / 100))).toFixed(2);
        } else if (discount.type === 'fixed') {
            return Math.max(0, amount - discountValue).toFixed(2);
        }
        return amount.toFixed(2);
    };

    const constructArgs = (data) => {
        const anonymousId = analytics.getAnonymousId();
        /* eslint-disable camelcase */
        return {
            ...router.query,
            account_id: accountId,
            anonymous_id: anonymousId,
            plan_id: planId,
            session_id: sessionId,
            order_id: data?.orderID,
            subscription_id: data?.subscriptionID,
            sandbox: SANDBOX_MODE,
            token,
            name,
            email,
            discount: discount?.discount || null,
            discount_code: discount?.code || null
        };
        /* eslint-enable camelcase */
    };

    // console.log('PayPalCheckoutButtons planID:', planId);
    // console.log('PayPalCheckoutButtons token:', token);
    // console.log('PayPalCheckoutButtons accountId:', accountId);
    // console.log('PayPalCheckoutButtons sessionId:', sessionId);
    // console.log('PayPalCheckoutButtons SANDBOX:', SANDBOX_MODE);

    // recurring payments
    const createPayPalSubscription = useCallback((data, actions) => {
        console.log('createPayPalOrder', planId, data, actions);

        // identify
        analytics.identify({ email, name, userType: 'parent', planId, subscriptionSource: 'paypal' });

        /* eslint-disable camelcase */
        return actions.subscription.create({
            plan_id: planId,
            quantity: '1',
            custom_id: accountId ?? token,
            application_context: {
                shipping_preference: 'NO_SHIPPING',
                brand_name: 'Muzology',
                user_action: 'SUBSCRIBE_NOW'
                // return_url: `${MUZOLOGY_API}/checkout/paypal/`,
                // cancel_url: window.location.href,
            }
        });
        /* eslint-enable camelcase */
    }, [accountId, analytics, email, name, planId, token]);

    const onApprovePayPalSubscription = useCallback(async (data, actions) => {
        console.log('[checkout] PayPal subscription approved', data, actions);

        onComplete?.('paypal', data, () => {});

        // track the event
        const plan = StripePlans.find(p => p.priceId === planId);
        // await analytics.track(CheckoutPurchaseComplete, {
        //     ...router.query,
        //     name,
        //     email,
        //     source: 'paypal',
        //     planId,
        //     planName: plan?.name, plan, purchase: data,
        //     testMode: SANDBOX_MODE
        // }, BlockKlaviyo);

        // await analytics.track(NewInitialPaidTeacher, args, options);
        // await analytics.track(NewPaidTeacher, args, options, callback);
        const args = {
            ...router.query,
            flow: teacher ? 'teacher' : 'parent',
            name,
            email,
            planId,
            plan,
            // value: plan?.amount,
            price: plan?.amount,
            planName: plan?.name,
            purchase: data,
            testMode: SANDBOX_MODE
        };

        if (teacher) {
            // teacher purchased a paid subscription
            await analytics.track(NewInitialPaidTeacher, args);
            await analytics.track(NewPaidTeacher, args);
        } else {
            // parent purchased a paid subscription upgrade
            await analytics.track(NewParentInitialPaid, args);
            await analytics.track(NewParentPaid, args);
        }

        // redirect to the server to complete the purchase

        // we need a full url to the server to complete the purchase
        const completeURL = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/paypal/`, constructArgs(data));
        console.log(`redirecting to ${completeURL} on completion`);
        window.location.href = completeURL;

        // // call the server to capture the funds from the transaction
        // axios.post('/api/checkout/paypal/', payload)
        //     .then((response) => {
        //         console.log('[Checkout]', response);
        //         if (!handleRedirection(response)) {
        //             console.error("Error handling redirection", response);
        //             return response.data;
        //         }
        //     })
        //     .catch((error) => {
        //         console.log('error', error);
        //         alert("There was an error:" + error.message);
        //     });

        // actions.subscription.get().then((details) => {
        //     console.log('details', details);
        //     alert('Transaction completed by ' + details.subscriber.name.given_name + '!');
        // });

        // get: () => Promise<SubscriptionDetail>;
        // activate: () => Promise<void>;
        // return actions.subscription.capture().then((details) => {
        //     console.log('details', details);
        //     alert('Transaction completed by ' + details.payer.name.given_name + '!');
        // });
    }, [analytics, email, name, planId, router.query, sessionId, token]);

    // one time orders
    const createPayPalOrder = useCallback((data, actions) => {
        console.log('[checkout] createPayPalOrder', planId, data, actions);

        const originalPrice = 9.99;
        const discountedPrice = applyDiscount(originalPrice);

        // identify
        // analytics.identify({ email, name, userType: 'parent', planId, subscriptionSource: 'paypal' });

        /* eslint-disable camelcase */
        return actions.order.create({
            purchase_units: [{
                description: 'Muzology Times Tables - 99 days access',
                amount: { value: discountedPrice, currency_code: 'USD' }
            }],
            items: [{ // Itemization of the purchase
                name: 'Muzology Times Tables for 99 days',
                description: '99 days of access to Muzology Times Tables',
                unit_amount: { currency_code: 'USD', value: discountedPrice },
                quantity: '1'
            }], // Optional: include a reference ID or similar
            application_context: {
                shipping_preference: 'NO_SHIPPING' // for digital goods
            }
        });
        /* eslint-enable camelcase */
    }, [planId]);

    const onApproveOrder = useCallback(async (data, actions) => {
        console.log('[checkout] PayPal order approved', data, actions);

        // redirect to the server to complete the purchase

        // Capture the funds from the transaction
        setLoading?.(true);
        return actions.order.capture().then(details => {
            // alert('Transaction completed by ' + details.payer.name.given_name);
            console.log('[checkout] captured order details:', details);

            // we need a full url to the server to complete the purchase
            const completeURL = createUrlWithParams(`${MUZOLOGY_API}/api/checkout/paypal_purchase/`, constructArgs(data));
            console.log(`redirecting to ${completeURL} on completion`);
            window.location.href = completeURL;
        });

    }, [setLoading]);

    // error handling
    const onError = (err) => {
        console.error('Error during the transaction', err);
    };

    return (
        <PayPalButtons
            // @ts-ignore
            style={payPalStyle}
            disabled={false}
            forceReRender={[payPalStyle]}
            fundingSource={fundingSource}
            createSubscription={recurring ? createPayPalSubscription : null}
            createOrder={recurring ? null : createPayPalOrder}
            onApprove={recurring ? onApprovePayPalSubscription : onApproveOrder}
            onError={onError}
        />
    );
};


export default PayPalCheckoutButtons;
