import React, { useMemo, useState } from 'react';
import { StripePlans } from '@/components/StripeCheckout/StripePlans';
import cx from 'classnames';

const StripePlanButtons = ({ selectedPlan, setSelectedPlan }) => {
    const [originalSelectedPlan, setOriginalSelectedPlan] = useState(selectedPlan);

    const plans = useMemo(() => {
        return StripePlans?.map((plan, index) => {
            const classes = cx(
                'select-none cursor-pointer text-left shadow-teacher-intro-mobile max-w-[300px] pb-5 sm:pb-7',
                'basis-full w-full p-[4px]   flex-col justify-center items-start gap-2 inline-flex',
                'rounded-[22px]',
                'p-1',
                { 'bg-[#3373EE]  shadow-teacher-intro-mobile mx-auto ': originalSelectedPlan === 2 }
            );

            if (originalSelectedPlan !== index) return null;

            return (
                <div tabIndex={index + 1} key={plan.id} onClick={(event) => setSelectedPlan && setSelectedPlan(event, index, plan)} className={classes}>
                    <div className='w-full py-3 sm:py-0 p-1 sm:p-2 flex items-center md:items-start flex-col gap-1.5 h-[150px] sm:h-full relative'>
                        <div
                            className={cx(
                                'DaysFreeThen14999Year text-center w-full ',
                                { 'mt-0': index === 0 },
                                { 'mt-1.5 sm:mt-[1.5rem]': index === 1 },
                                { 'mt-3 sm:mt-4': index === 2 }
                            )}
                        >
                            {/*
                            <span className={cx('text-base font-medium font-Poppins leading-7 text-[#3F3E3E]', { 'text-white': index === 0 })}>
                                <span className='font-bold'>7 days FREE</span> then
                                <br />
                            </span>
                            */}
                            <div
                                className={cx(
                                    'text-[31px] font-medium font-Poppins leading-7 mt-10 text-[#231D4F]',
                                    { 'text-white': index === 2 },
                                    { 'sm:mt-7': index === 0 }
                                )}
                            >
                                {plan.amount}
                                <div className='text-[24px] mt-1.5'>{plan.name}</div>
                                {index === 1 && <div className='text-[#231D4F] text-lg text-center mt-1'>(only $15/month)</div>}
                                {index === 2 && <div className='relative z-20 text-[white] text-lg text-center mt-1'>(only $8.75/month)</div>}
                            </div>
                        </div>
                        {index === 2 && (
                            <div className='absolute w-full rounded-[30px] top-[-30px] right-[0%] z-[0] '>
                                <div className='mx-auto w-full flex justify-center mt-10'>
                                    <div className='bg-[#1DC7E0]  w-[170px]  h-[30px] text-center  rounded-[30px] text-sm lg:text-base text-[#fff] font-semibold flex justify-center items-center'>
                                        7 DAY FREE TRIAL
                                    </div>
                                </div>
                            </div>
                        )}
                        {index === 1 && (
                            <div className='absolute w-full rounded-[30px] top-[-30px] right-[0%] z-[0] '>
                                <div className='mx-auto w-full flex justify-center mt-10'>
                                    <div className='bg-[#1DC7E0]  w-[170px]  h-[30px] text-center  rounded-[30px] text-sm lg:text-base text-[#fff] font-semibold flex justify-center items-center'>
                                        MOST POPULAR!
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }, [originalSelectedPlan, setSelectedPlan]);
    console.log('plan elements:', plans);
    return (
        <div>
            <div className='PlanList relative flex flex-row-reverse justify-center w-full gap-4 sm:px-2 mt-5 sm:mt-0'>{plans}</div>
        </div>
    );
};

export default StripePlanButtons;
